import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom';
import { Button, Heading, Paragraph } from 'suomifi-ui-components'
import RequestCredential from '../../components/RequestCredential';
import { SimplifiedCredential, SimplifiedPresentationPayload } from '../../api/vce-api/vce-api';
import Pid, { PidCredentialSdJwt, PidCredentialVcJwt } from '../credentials/Pid';
import vceApi from '../../vceApi';

type Props = {}

const KycPID = (props: Props) => {

  // const verifierName = "verifier";

  const { pidState } = useOutletContext<{
    pidState: [boolean, Dispatch<SetStateAction<boolean>>], 
    lpidState: [boolean, Dispatch<SetStateAction<boolean>>], 
    euccState: [boolean, Dispatch<SetStateAction<boolean>>]}>();
  
  const [pidCompleted, setPidCompleted ] = pidState;
  const storedPid = localStorage.getItem("pid");
  debugger;
  // TODO refactor
  const [ pid, setPID ] = useState<SimplifiedCredential|undefined>(storedPid !== null ? JSON.parse(storedPid) : undefined); 
  
  const pidClaimsCSV = ["given_name","family_name","birth_date"].join(",");

  const onCallback = (data: SimplifiedPresentationPayload) => {
    debugger
    localStorage.setItem("pid", JSON.stringify(data.credentials[0], null, 2));
    setPID(data.credentials[0]);
    setPidCompleted(true);
  }

  // const { isPending, error, data} = useQuery<{ take?: number; skip?: number; count: number; logs: LogEntity[]; }>({
  //   queryKey: ['logData', showDebug, showInformation, showWarning, showError, showFetch, pageSize, current],
  //   queryFn: () =>
  //     updateLogs(showDebug, showInformation, showWarning, showError, showFetch, pageSize, current).then((res) => 
  //       res.json(),
  //     ),
  // })


  const onClearPid = () => {
    localStorage.removeItem("pid");
    setPID(undefined);
    setPidCompleted(false);
  }

  

  return (
    <div>
        <Heading variant='h3'>Natural Person Authentication</Heading>
        { !pidCompleted && 
        <>
          <Paragraph>Here you can authenticate as a natural person</Paragraph>

          <RequestCredential callback={onCallback} showWalletAddress={false} credentialType='PID' showOptions={true} claimsCSV={pidClaimsCSV}></RequestCredential>
          {/* <RequestCredential callback={onCallback} showWalletAddress={false} credentialType='eu.europa.ec.eudi.pid.1,eu.europa.ec.eudi.pid.*.1,PID' showOptions={true} claimsCSV={pidClaimsCSV}></RequestCredential> */}
        </>
      }
      { pidCompleted && pid && 
        <>
          <Paragraph>You have authenticated with following details:</Paragraph>
          <Pid credential={pid} onClear={ onClearPid }></Pid>
        </>
      }

      <br/>
      <Link to="/kyc/lpid">
        <Button disabled={!pid}>Next step</Button>
      </Link>


    </div>
  )
}

export default KycPID