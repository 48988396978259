import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code';
import vceApi from '../vceApi';
import { Button, Checkbox, Dropdown, DropdownItem, Paragraph, TextInput } from 'suomifi-ui-components';
import { useTranslation } from 'react-i18next';
import { SimplifiedPresentationPayload } from '../api/vce-api/vce-api';

type Props = {
  readonly credentialType: string,
  readonly claimsCSV: string,
  readonly client_id_scheme?: "did"|"redirect_uri",
  readonly pathType?: "dc+sd-jwt"|"vc+sd-jwt"|"jwt_vc_json",
  readonly callback: (data: SimplifiedPresentationPayload)=>void,
  readonly title?: string
  readonly showWalletAddress?: boolean
  readonly showOptions?: boolean
}


function RequestCredential({ callback, credentialType, claimsCSV, client_id_scheme, pathType, title, showWalletAddress, showOptions }: Props) {
  // qrcode related stuff
  const [state, setState] = useState<string>("");
  const [counter, setCounter] = useState<number>(-1);
  const [redirectUri, setRedirectUri] = useState<string | undefined>(undefined);
  const [showRenew, setShowRenew] = useState<boolean>(false);
  const { t } = useTranslation();

  const [walletAddress, setWalletAddress] = useState<string|undefined>(localStorage.getItem("walletAddress")??"");
  const [errorText, setErrorText] = useState<string|undefined>("");

  const [toggleSettings, setToggleSettings] = useState<boolean>(false)
  const [togglePresentation, setTogglePresentation] = useState<boolean>(false)
  const [presentationData, setPresentationData] = useState<string>("")
  
  const [ clientIdScheme, setClientIdScheme ] = useState<"did"|"redirect_uri"|"x509_san_dns"|"">(client_id_scheme??"redirect_uri");  // default value redirect_uri, also in server
  const [ formatType, setFormatType ] = useState<"dc+sd-jwt"|"vc+sd-jwt"|"jwt_vc_json"|"">(pathType??"");  
  const [ useCredentialType, setUseCredentialType ] = useState<boolean>(true);

  const onSendRequestToWalletClick = async () => {
    if (!walletAddress || !redirectUri)
      return;
    // Trim wallet address and try to post open id request to the address
    const walletAddressTrimmed = walletAddress.trim();
    if (walletAddressTrimmed.length > 0) {
      let holderUrl = walletAddress;
      localStorage.setItem("walletAddress", holderUrl);
      if (walletAddressTrimmed.startsWith("did:web:")) { 
        holderUrl = (walletAddressTrimmed.indexOf("localhost")>=0 ? "http://" : "https://") + decodeURIComponent(walletAddressTrimmed.substring(8).replaceAll(":", "/"))
        if (!holderUrl.endsWith("/authorize")) {
          holderUrl = holderUrl + "/authorize";
        }
      }
      else {
        holderUrl = walletAddressTrimmed;
      }
      try {
        // TODO const client_id_metadata = {} and append info to fetch
        const getResult = await fetch(holderUrl + "?" + redirectUri.replace("openid4vp://?", ""));
        console.log("get result", JSON.stringify(getResult));
      }
      catch(err: any) {
        console.log(err);
        setErrorText(err.messge)
      }
    }
  }

  const renewQrCode = () => {
    setCounter(-1);
    setShowRenew(false);
  }

  const verifierName = "verifier";
  useEffect(() => {
    if (state) { // state is changed every 3 minutes
      // Create PID presentation request
      vceApi.verifiers.createPresentationRequestSimple(verifierName, credentialType, { 
        claimsCSV: claimsCSV, 
        state: state, /*clientId: `did:web:test.minisuomi.fi:api:verifiers:${verifierName}`*/ 
        filterType: useCredentialType,
        pathType: formatType === "" ? undefined : formatType, 
        client_id_scheme: clientIdScheme === "" ? undefined : clientIdScheme
      }).then(x => {
        console.log(`redirectUri: ${x.data.redirectUri}`); 
        
        setPresentationData(JSON.stringify(x.data, null, 2));
        vceApi.verifiers.initiateTransaction(verifierName, x.data).then(x => {
          console.log(x.data.responseUri);

          
          // Update url for qr code and start counter
          setRedirectUri(x.data.responseUri);
          setCounter(0);
  
        }).catch(x => console.log(x));
      }).catch(x => console.log(x));
    }
  }, [state, claimsCSV, credentialType, useCredentialType, clientIdScheme, formatType]);

  useEffect(() => {
    if (counter === -1 && !state) {
      // Init state
      const newState = window.crypto.randomUUID().replace(/-/g,"");
      setState(newState);
    } else if (counter >= 0) {
      if (counter >= 10) { 
        console.log("Counter >= 10");
        setState("");
        setRedirectUri("");
        setShowRenew(true);
      }
      else {
        // Polling function tries to poll about 30 seconds, credential presentation is valid about 3 minutes
        vceApi.verifiers.poll(verifierName, state, { credentials: "omit"}).then(x => {
          const data = x.data;
          console.log(data);  
          if (data !== null) {
            callback(data);
          }
          else {
            console.log("not verified yet");
            setCounter(counter+1);
          }
        }).catch((e: Error) => {
          console.log(e);
          if (e.message === "state not found") {
            // state not found, do not continue polling
          }
          else {
            console.log("unkonwn polling error? trying again...");
            setCounter(counter+1);
          }
          
        });

      }
    }
  }, [counter, callback, state])

  return (<div style={{ display: "flex", alignItems: "center", flexDirection: "column"}}>
    { showOptions && 
      <div style={{marginBottom: "10px" }}>
        <Paragraph style={{color: toggleSettings ? "#003479" : "#ccc", cursor: "pointer"}} onClick={() => {setToggleSettings(!toggleSettings)}}>{toggleSettings ? 'hide compatibility settings' : 'show compatibility settings'}</Paragraph>
        { toggleSettings && 
          <>
          <Dropdown key="format_dropdown"
            labelText="Format"
            value={formatType}
            onChange={(newValue) => { setRedirectUri(""); setFormatType(newValue as "vc+sd-jwt"|"jwt_vc_json"|"") }}
          >
            <DropdownItem key="any" value="">*</DropdownItem>
            <DropdownItem key="vc+sd-jwt" value="vc+sd-jwt">sd-jwt</DropdownItem>
            <DropdownItem key="jwt_vc_json" value="jwt_vc_json">jwt</DropdownItem>  
          </Dropdown>

          <Dropdown key="client_id_scheme_dropdown"
            labelText="Client id scheme"
            value={clientIdScheme}
            onChange={(newValue) => { setRedirectUri(""); setClientIdScheme(newValue as "redirect_uri"|"did"|"x509_san_dns"|"") }}
          >
            <DropdownItem key="empty" value=""> </DropdownItem>
            <DropdownItem key="did" value="did">did</DropdownItem>
            <DropdownItem key="redirect_uri" value="redirect_uri">redirect_uri</DropdownItem>  
            <DropdownItem key="x509_san_dns" value="x509_san_dns">x509_san_dns</DropdownItem>  
          </Dropdown>


          <Checkbox checked={useCredentialType} onClick={(newState) => { setUseCredentialType(newState.checkboxState); }} defaultChecked={true} >{t("requestCredential.useCredentialType")}</Checkbox>
          </>
        }
      </div>
    }
    {redirectUri &&
      <div style={{ height: "auto", margin: "0 0", maxWidth: 192, width: "100%" }} title={`${credentialType}${formatType ? " (" + formatType + ")" : ''}: ${claimsCSV}`}>
        <a href={redirectUri}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={redirectUri}
            viewBox={`0 0 256 256`}
          />
        </a>
        <p style={{textAlign: "center", margin: 0}}>{title??credentialType}</p>
      </div>
      }
      { showRenew && 
        <Button onClick={renewQrCode}>{t("renew")}</Button>
      }
    { showWalletAddress && <div style={{display:"flex", flexDirection:"row", alignItems: "end"}}>
        <br/>
          <TextInput readOnly={false} className='longer-input' name='walletAddress' labelText={t("eudtrcView.walletAddress")} value={walletAddress} onChange={(e) => setWalletAddress(e as string)}></TextInput>
          <Button style={{marginBottom: "7px"}} disabled={!walletAddress} onClick={() => onSendRequestToWalletClick() }>Send request to wallet</Button>
          { errorText }
        </div>
      }

      { showOptions && 
      
      <div style={{marginBottom: "10px"}}>
        <Paragraph style={{color: togglePresentation ? "#003479" : "#ccc", cursor: "pointer"}} onClick={() => {setTogglePresentation(!togglePresentation)}}>{togglePresentation ? 'hide presentation' : 'show presentation'}</Paragraph>
        { togglePresentation && 
        
          <pre style={{ fontSize: "12px" }}>TEST {presentationData}</pre>
        }
      </div>
      }
      </div>
  )
}

export default RequestCredential
