import React, { useState } from 'react'
import { Button, Heading, IconClose } from 'suomifi-ui-components';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { t } from 'i18next';
import { JWK, SimplifiedCredential } from '../../api/vce-api/vce-api';
import CheckFederation from '../../components/CheckFederation';


// TODO VcJwt support

export interface LpidCredentialSdJwt {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK

  legal_person_id: string // this is must have to field, no disclosure?
  
  legal_person_name: string
  issuer_name: string
  issuer_id: string
  issuer_country: string
  issuance_date: string
  expiry_date: string
  authentic_source_id: string
  authentic_source_name: string
  credential_status: string
  credential_schema: string
}



type Props = { 
  credential: SimplifiedCredential //LpidCredentialSdJwt // | LpidCredentialJwt
  onClear: () => void
}

const Lpid = (props: Props) => {
  const [visualize, setVisualize] = useState<boolean>(true);
  // TODO support other than sd-jwt:
  const vc = /*props.credential.vc?.credentialSubject ||*/ props.credential.payload;

  //const trusted = props.trusted;

  return (
    <Paper style={{paddingTop: "30px", paddingBottom: "50px" }}>

      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("credentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>

      { !visualize ? <pre>{JSON.stringify(vc, null, 2)}</pre> :
      <div>
        <p>
          Date of creating the document: { vc?.issuance_date ?? "-" }<br/>
        </p>
        <Heading variant='h3'>LEGAL PERSON IDENTIFICATION DATA (LPID)</Heading>
        <br/>
        
        
        <table cellSpacing={0} style={{textAlign: "left"}}>
          <tbody>
          <tr>
            <th colSpan={3}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Identifiers</Heading>
            </th>
          </tr>
          <tr>
            <td title="Name of the company">Company name</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legal_person_name}</td>
          </tr>
          <tr>
            <td title="EU Business Identity Code">EUID</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legal_person_id}</td>
          </tr>

          <tr><td><br/></td></tr>

          </tbody>
        </table>

        <table cellSpacing={0} style={{textAlign: "left"}}>
          <tbody>
          <tr>
            <th colSpan={3}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Issuer</Heading>
            </th>
          </tr>
          <tr>
            <td title="Name of the issuer">Issuer name</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.issuer_name}</td>
          </tr>
          <tr>
            <td title="Issuer country">Issuer country</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.issuer_country}</td>
          </tr>
          <tr>
            <td title="Issuer id">Issuer id</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.issuer_id}</td>
          </tr>
          <tr>
            <td title="Issuer url">Issuer url</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.iss}</td>
          </tr>
          </tbody>
        </table>
        <br/>
        
        <table cellSpacing={0}>
          <tbody>
            <tr>
              <td style={{verticalAlign: "center"}}>Date of issuance</td><td style={{width: "40px"}}></td>
              <td style={{verticalAlign: "center"}}>E-signature</td>
            </tr>
            <tr>
              <td>
                <div style={{borderBottom: "1px solid black"}}>{ /*vc.registrationDate ||*/new Date().toLocaleDateString(/*selectedLanguage*/)}</div>
              </td>
              <td></td>
              <td>
                <div style={{borderBottom: "1px solid black"}}>
                  verified by {vc.issuer_name||vc.iss}
                </div>
                {/* <div style={{borderBottom: "1px solid black"}}><a href="https://pankki.minisuomi.net" rel="noreferrer" target="_blank" title={vc.legalName}></a></div> */}
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
        <CheckFederation credential={props.credential} name='lpid'></CheckFederation>
        <div style={{display: "flex", flexDirection: "row", marginTop:"10px"}}>
          <span style={{flexGrow: 1}}></span>
          <Button variant='secondary' icon={<IconClose></IconClose>} onClick={ props.onClear }>Clear form</Button>
        </div>
        
      </div> 
      }
    </Paper>
  )
}

export default Lpid